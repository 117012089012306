// Font sizes
$font-size-base: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;
$font-size-sm: 14px;
$font-size-xs: 12px;
$font-size-xxs: 10px;

// Font weights
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

// Headings
%heading {
  font-weight: $font-weight-bold;
}

// Body text
.text {
  font-size: $font-size-base;
  line-height: 24px;
}

.text-lg {
  font-size: $font-size-lg;
  line-height: 28px;
}

.text-xl {
  font-size: $font-size-xl;
  line-height: 28px;
}

.text-sm {
  font-size: $font-size-sm;
  line-height: 20px;
}

.text-xs {
  font-size: $font-size-xs;
  line-height: 18px;
}
.text-xxs {
  font-size: $font-size-xxs;
  line-height: 16px;
}

// Text alignment
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// Text transformations
.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

// Mixins
@mixin font($size, $weight) {
  font-size: $size;
  font-weight: $weight;
}

@mixin line-height($height) {
  line-height: $height;
}

@mixin font-weight($weight) {
  font-weight: $weight;
}

@mixin text-color($color) {
  color: $color;
}
