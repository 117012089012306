@import '@/styles/base/base';

@for $i from 1 through $turns-max {
  .rotate-#{$i} {
    @include rotate($i);
  }
}

.icon {
  stroke: $gray-600;
  flex: none;
}
