@import '@/styles/base/base';

.container {
  @extend %v-center;
  .icon {
    margin-bottom: 16px;
  }
  .title {
    font-size: 16px;
    line-height: 24px;
    color: $gray-900;
    margin-bottom: 4px;
  }
  .description {
    font-size: 14px;
    line-height: 20px;
    color: $gray-600;
    margin-bottom: 20px;
  }
}
