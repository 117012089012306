@import '@/styles/base/base';

$primary-color: $primary-600;

.button {
  @extend %h-center;
  border-radius: $border-radius;
  padding: 10px 16px;
  @extend .text-sm;
  font-weight: $font-weight-semi-bold;
  cursor: pointer;
  transition: all 0.3s ease-out;
  height: max-content;
  width: max-content;
  border: none;
  background: none;

  &:disabled {
    cursor: not-allowed;
  }

  &.primary {
    background: $primary-color;
    color: white;

    &:hover {
      background: darken($primary-color, 5%);
    }
    &:disabled {
      background: rgba($color: $primary-color, $alpha: 0.6);
    }
  }
  &.secondary {
    color: $gray-700;
    border: 1px solid $gray-300;
    box-shadow: $shadow-xs;

    &:disabled {
      color: rgba($color: $gray-700, $alpha: 0.4);

      svg {
        stroke: rgba($color: $gray-700, $alpha: 0.4);
      }
    }
  }
  &.tertiary {
    background-color: $primary-50;
    color: $primary-700;
    svg {
      stroke: $primary-700;
    }
    &:hover {
      background-color: $primary-100;
    }
  }
  &.icon {
    padding: 10px;

    &:hover {
      background-color: $gray-100;
      svg {
        stroke: $gray-700;
      }
    }
    &:disabled {
      color: rgba($color: $gray-700, $alpha: 0.4);

      &.secondary {
        background-color: white;
      }

      svg {
        stroke: rgba($color: $gray-700, $alpha: 0.4);
      }
    }
    &.secondary {
      box-shadow: $shadow-xs;
      border: 1px solid $gray-300;
    }
    &.primary {
      background: none;
      svg {
        stroke: $primary-600;
        &:hover {
          stroke: $primary-700;
        }
      }
    }
  }
  &.text {
    background: none;
    border: none;
    box-shadow: none;
    padding: 10px 0;

    &.primary {
      color: $primary-600;
      svg {
        stroke: $primary-600;
      }
      &:hover {
        background: none;
        color: $primary-700;
        svg {
          stroke: $primary-700;
        }
      }
    }
  }

  &.center {
    @extend %center;
  }

  &.fullWidth {
    @extend %full-width;
  }

  .loader {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
