@import 'base/reset';
@import 'base/base';

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: $gray-600;
}

* {
  font-family: $font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.flatfile_iframe-wrapper {
  width: 100% !important;
  height: 100% !important;

  .flatfile_iFrameContainer {
    width: 100%;
    height: 100% !important;
  }
}
