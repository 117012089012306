%flex {
  display: flex;
}

.d-flex {
  @extend %flex;
}

%justify-between {
  @extend %flex;
  justify-content: space-between;
}

.justify-between {
  @extend %justify-between;
}

%flex-col {
  display: flex;
  flex-direction: column;
}

%h-center {
  display: flex;
  align-items: center;
}

.h-center {
  @extend %h-center;
}

%center {
  @extend %h-center;
  justify-content: center;
}

%v-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

%margin-auto {
  margin: auto;
}

%full-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

%full-fill {
  height: 100vh;
  width: 100%;
}

%full-width {
  width: 100%;
}

%full-height {
  height: 100vh;
}

%rounded {
  border-radius: 50%;
}

%error-text {
  @extend .text-sm;
  color: $error-700;
  margin-top: 6px;
}
