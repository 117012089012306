@import '@/styles/base/base';

.sidebar {
  @extend %v-center;
  @extend %full-height;
  width: 64px;
  padding: 16px;
  background-color: #edeff2;
  border-right: 1px solid $gray-200;

  .content {
    @extend %flex-col;
    @extend %justify-between;
    flex: 1;

    .item {
      cursor: pointer;
      @extend %center;

      a {
        padding: 8px;
        line-height: 0;
      }
      &.active,
      &:hover {
        background-color: #dfe3e9;
        border-radius: $border-radius-md;
      }

      .icon {
        stroke: $gray-500;
      }
      .iconApps {
        fill: $gray-500;
      }
    }

    .main,
    .footer {
      @extend %v-center;
      gap: 8px;
    }
  }
}
