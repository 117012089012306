// styling variables
$primary-50: #f9f5ff;
$primary-100: darken(#f9f5ff, 5%);
$primary-600: #7f56d9;
$primary-700: #6941c6;
$primary-800: darken(#6941c6, 10%);

/// BASIC COLORS
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-400: #d9d9d9;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: darken($gray-700, 10%);
$gray-900: #101828;

/// ERROR COLORS
$error-25: #fffbfa;
$error-50: #fef3f2;
$error-300: #fda29b;
$error-700: #b42318;

$font-family: 'Inter', sans-serif !important;

// border radius
$border-radius-sm: 4px;
$border-radius-md: 6px;
$border-radius: 8px;
$border-radius-lg: 12px;

// border
$border-200: 1px solid $gray-200;

// box shadow
$shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
$shadow-lg: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
  0px 4px 6px -2px rgba(16, 24, 40, 0.03);
$shadow-xl: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
  0px 8px 8px -4px rgba(16, 24, 40, 0.03);

$shadow-xxl: 0px 12px 16px -4px rgba(16, 24, 40, 0.38),
  0px 4px 6px -2px rgba(16, 24, 40, 0.03);

// constant variables
$turns-max: 4;
$sidebar-width: 350px;
