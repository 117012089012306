@mixin rotate($turns) {
  transform: rotate($turns * 90deg);
}

@mixin truncate($max-width) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}
