@import '@/styles/base/base';

.container {
  @extend %flex;
  @extend %full-height;

  .main {
    @extend %flex-col;
    flex: 1;
  }
}
