@import '@/styles/base/base';

.spinner {
  position: relative;
  width: 20px;
  height: 20px;
}

.spinnerInner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: white;
  border-right-color: white;
  animation: spinner 1s linear infinite;

  &.primary {
    border-top-color: $primary-700;
    border-right-color: $primary-700;
  }
  &.secondary {
    border-top-color: white;
    border-right-color: white;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
